import React, { useEffect, useState } from "react";
import { preloadScript } from "opentok-react";
import { StringParam, useQueryParam } from "use-query-params";
import { useIntl } from "react-intl";
import { Redirect } from "react-router-dom";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import { useCustomerId } from "../../Providers/CustomerProvider/CustomerProvider";
import { MeetingStatusType } from "../../generated/meeting-api";
import {
  redirectToErrorPage,
  RedirectToErrorPage,
} from "../ErrorPage/ErrorPage";
import { messages } from "./SessionVideo.messages";
import { RoutePath } from "../../Components/Routes/RoutePath";
import VideoRoom from "./VideoRoom/VideoRoom";
import { meetingService } from "../../Providers/ServiceProvider/ServiceProvider";
import { useSpinner } from "../../Components/Spinner/Spinner";
import WebSocketSession from "../../WebSocket/WebSocketSession";
import { MeetingSession } from "../../Models/Meeting";
import useIgnoreBackButton from "../../Hooks/useIgnoreBackButton";

const SessionVideo = () => {
  useIgnoreBackButton();
  const intl = useIntl();
  const customerId = useCustomerId();
  const [authenticatedUser] = useAuthenticatedUser();
  const [session, setSession] = useState<MeetingSession>();
  const [meetingId] = useQueryParam("meetingId", StringParam);
  const setSpinner = useSpinner()[1];

  const handleError = (status: number) => {
    switch (status) {
      case 400:
        redirectToErrorPage({
          errorCode: 400,
          title: intl.formatMessage(messages.meetingNotFoundErrorTitle),
          description: intl.formatMessage(
            messages.meetingNotFoundErrorDescription
          ),
        });
        break;
      case 404:
        redirectToErrorPage({
          errorCode: 404,
          title: intl.formatMessage(messages.meetingNotFoundErrorTitle),
          description: intl.formatMessage(
            messages.meetingNotFoundErrorDescription
          ),
        });
        break;
      default:
        redirectToErrorPage({});
    }
  };

  const fetchMeetingSessionDetails = (): void => {
    if (customerId && authenticatedUser.user && meetingId) {
      meetingService()
        .getMeetingSessionDecorated(authenticatedUser.user, {
          customerId,
          meetingId,
          userId: authenticatedUser.user.id,
        })
        .then((res) => setSession(res))
        .catch((reason) => handleError(reason.status));
    }
    setSpinner({ isLoading: false });
  };

  useEffect(() => {
    fetchMeetingSessionDetails();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  if (!meetingId) {
    return <RedirectToErrorPage />;
  }

  useEffect(() => { // eslint-disable-line
    const interval = setInterval(() => {
      const videosBackground: any = document.getElementsByClassName(
        "OT_video-poster"
      );
      if (videosBackground) {
        for (let i = 0; i < videosBackground.length; i += 1) {
          videosBackground[i].style.backgroundImage =
            "url(/Careplatform_logo.png)";
        }
      }
    }, 300);
    return () => clearInterval(interval);
  });

  if (session?.meeting.status === MeetingStatusType.Completed) {
    return <Redirect to={RoutePath.DASHBOARD} />;
  }

  return (
    <WebSocketSession>
      {session?.sessionInfo && (
        <VideoRoom meeting={session.meeting} session={session.sessionInfo} />
      )}
    </WebSocketSession>
  );
};

export default preloadScript(SessionVideo);
