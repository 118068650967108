import React, { useEffect, useState } from "react";
import { Button, useMediaQuery, useTheme } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";

import { useIntl } from "react-intl";
import {
  UserResponse,
  UserRoleType,
  UserStatusType,
} from "../../../generated/user-api";
import { messages } from "./AddOrCreateUserDialog.messages";
import { User } from "../../../Models/User";
import SearchField, {
  Query,
  Variant,
} from "./Components/SearchField/SearchField";
import SearchResult from "./Components/SearchResult/SearchResult";
import {
  inviteService,
  userService,
} from "../../../Providers/ServiceProvider/ServiceProvider";
import { useCustomerId } from "../../../Providers/CustomerProvider/CustomerProvider";
import CreateUserDialog from "../../Buttons/CreateUserButton/CreateUserDialog";
import { useCustomerCurrentUpdate } from "../../../Providers/RecentUpdatesProvider/RecentUpdatesProvider";
import { useAuthenticatedUser } from "../../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";

interface Props {
  onCreated: ((user: User) => void) | (() => void);
  isOpen: boolean;
  title: string;
  onClose: () => void;
  userRole: UserRoleType;
  variant: Variant;
  sendNotification: boolean;
}

const AddOrCreateUserDialog = (props: Props) => {
  const {
    isOpen,
    onClose,
    title,
    onCreated,
    userRole,
    variant,
    sendNotification,
  } = props;
  const intl = useIntl();
  const theme = useTheme();
  const customerId = useCustomerId();
  const [authenticatedUser] = useAuthenticatedUser();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const [matchingUsers, setMatchingUsers] = useState<User[]>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [searchQuery, setSearchQuery] = useState<Query>();
  const [
    lastCompletedSearchQuery,
    setLastCompletedSearchQuery,
  ] = useState<Query>();
  const [showCreateFields, setShowCreateFields] = useState<boolean>(false);
  const [customerupdates, setCustomerupdates] = useCustomerCurrentUpdate();

  const cleanUp = () => {
    setMatchingUsers(undefined);
    setShowCreateFields(false);
    onClose();
  };

  const handleSelected = (user: User) => {
    if (user.userRole === UserRoleType.External) {
      onCreated({ ...user, personNumber: undefined });
    } else {
      onCreated(user);
    }
    cleanUp();
  };

  const handleCreateNew = () => {
    setShowCreateFields(true);
  };

  const getCreateUserDialogTitle = () => {
    switch (userRole) {
      case UserRoleType.Staff:
        return intl.formatMessage(messages.dialogTitleStaff);
      case UserRoleType.External:
        return intl.formatMessage(messages.dialogTitleExternal);
      case UserRoleType.Client:
        return intl.formatMessage(messages.dialogTitleClient);
      default:
        return "Unknown";
    }
  };

  const handleInvite = () => {
    if (customerId && searchQuery?.email) {
      inviteService(true)
        .createInvite({
          customerId,
          body: {
            email: searchQuery?.email,
            userRole,
            sendNotification,
          },
        })
        .then((res) => {
          const arrayCopy: UserResponse[] = customerupdates.userResponse;
          const user = {
            id: res.userId,
            email: res.email,
            userRole,
            firstName: "",
            lastName: "",
            userStatus: UserStatusType.Active,
            hasPersonNumber: false,
            hasHsaId: false,
            hasPassword: false,
            hasActivatedPassword: false,
          };
          arrayCopy.push(user);
          setCustomerupdates({ userResponse: arrayCopy });
          onCreated(user);
          cleanUp();
        });
    }
  };

  const searchForUser = () => {
    if (customerId && authenticatedUser.user && searchQuery) {
      setIsLoading(true);
      userService()
        .listUsersByCustomerAndSearchParamsDecorated(authenticatedUser.user, {
          personNumber: searchQuery.personNumber,
          email: searchQuery.email,
          customerId,
          userStatus: UserStatusType.Active,
          userRole,
        })
        .then((res) => {
          setMatchingUsers(res.data);
          setLastCompletedSearchQuery(searchQuery);
        });
    }
  };

  useEffect(() => {
    searchForUser();
  }, [searchQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsLoading(lastCompletedSearchQuery !== searchQuery);
  }, [lastCompletedSearchQuery]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Dialog
      fullScreen={fullScreen}
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={cleanUp}
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {!showCreateFields && (
          <>
            <SearchField variant={variant} onSearch={setSearchQuery} />
            <SearchResult
              isLoading={isLoading}
              query={searchQuery}
              users={matchingUsers}
              onCreateNew={handleCreateNew}
              onSelect={(user) => handleSelected(user)}
              onInvite={handleInvite}
            />
          </>
        )}
        {customerId && (
          <CreateUserDialog
            title={getCreateUserDialogTitle()}
            isOpen={showCreateFields}
            onCreated={handleSelected}
            onClose={cleanUp}
            userRole={userRole}
            preFilledPersonNumber={searchQuery?.personNumber}
            customerId={customerId}
          />
        )}
      </DialogContent>
      <DialogActions>
        {showCreateFields && (
          <Button variant="contained" type="submit" color="primary">
            {intl.formatMessage(messages.submitButtonLabel)}
          </Button>
        )}
        <Button onClick={cleanUp} color="primary">
          {intl.formatMessage(messages.cancelButtonLabel)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddOrCreateUserDialog;
