import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { Redirect } from "react-router-dom";
import { messages } from "./AdminBookings.messages";
import BookingsList from "../../Components/BookingsList/BookingsList";
import {
  useCustomerFeatures,
  useCustomerId,
} from "../../Providers/CustomerProvider/CustomerProvider";
import { useCustomerCurrentBooking } from "../../Providers/RecentUpdatesProvider/RecentUpdatesProvider";
import { useAuthenticatedUser } from "../../Providers/AuthenticatedUserProvider/AuthenticatedUserProvider";
import {
  MeetingHistoryType,
  PaginatedMeetingResponseList,
  SortOrderType,
} from "../../generated/meeting-api";
import CustomPagination from "../../Components/CustomPagination/Pagination";
import SearchBar from "../../Components/SearchBar/SearchBar";
import { Variant } from "../../Components/BookingsList/Components/Variant/Variant";
import { useStyles } from "./AdminBookings.style";
import CreateMeetingButton from "../../Components/Buttons/CreateMeetingButton/CreateMeetingButton";
import { useSpinner } from "../../Components/Spinner/Spinner";
import { hasPermission } from "../../Components/PermissionController/PermissionController";
import { UserAuthorityType } from "../../generated/user-api";
import { RoutePath } from "../../Components/Routes/RoutePath";
import { meetingService } from "../../Providers/ServiceProvider/ServiceProvider";
import { hasFeatureEnabled } from "../../Components/FeatureController/FeatureController";
import { CustomerFeatureType } from "../../generated/customersettings-api";

const AdminBookings = () => {
  const intl = useIntl();
  const classes = useStyles();
  const customerId = useCustomerId();
  const [authenticatedUser] = useAuthenticatedUser();
  const [page, setPage] = useState<number>(1);
  const [pageSize] = useState<number>(10);
  const [query, setQuery] = useState<string>();
  const setSpinner = useSpinner()[1];
  const customerFeatures = useCustomerFeatures();
  const [
    upcomingBookings,
    setUpcomingBookings,
  ] = useState<PaginatedMeetingResponseList>();
  const [customerupdates] = useCustomerCurrentBooking();
  const [
    isUpcomingBookingsLoading,
    setIsUpcomingBookingsLoading,
  ] = useState<boolean>(false);

  const isLoading = (): boolean => {
    return isUpcomingBookingsLoading;
  };

  const loadUpcomingBookings = () => {
    if (customerId && authenticatedUser.user?.id && query && query.length > 9) {
      const formattedQuery = query.replace("-", "").replace(" ", "");
      setIsUpcomingBookingsLoading(true);
      meetingService()
        .getMeetingsForCustomer({
          page,
          pageSize,
          customerId,
          personNumber: formattedQuery !== "" ? formattedQuery : undefined,
          history: MeetingHistoryType.Future,
          sortOrder: SortOrderType.Ascending,
        })
        .then((res) => setUpcomingBookings(res))
        .finally(() => setIsUpcomingBookingsLoading(false));
    }
  };

  const loadUpcomingBookingList = () => {
    if (customerId && authenticatedUser.user?.id) {
      setIsUpcomingBookingsLoading(true);
      meetingService()
        .getMeetingsForCustomer({
          page,
          pageSize,
          customerId,
          history: MeetingHistoryType.Future,
          sortOrder: SortOrderType.Ascending,
        })
        .then((res) => setUpcomingBookings(res))
        .finally(() => setIsUpcomingBookingsLoading(false));
    }
  };

  const loadBookings = () => {
    if (
      hasFeatureEnabled(CustomerFeatureType.AdminBookingList, customerFeatures)
    ) {
      loadUpcomingBookingList();
    } else {
      loadUpcomingBookings();
    }
  };

  const hasAdminRoutePermission = hasPermission({
    currentUser: authenticatedUser.user,
    requiredAuthoritiesStaff: [UserAuthorityType.AdminSite],
  });

  useEffect(() => {
    loadBookings();
  }, [page, pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (page !== 1) {
      // Reset pagination when search is triggered
      setPage(1);
    } else {
      loadUpcomingBookings();
    }
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSpinner({ isLoading: isLoading() });
  }, [isLoading()]); // eslint-disable-line react-hooks/exhaustive-deps
  if (!hasAdminRoutePermission) {
    return <Redirect exact to={RoutePath.DASHBOARD || ""} />;
  }
  return (
    <>
      <div className={classes.actionContainer}>
        <CreateMeetingButton
          onCreated={loadUpcomingBookings}
          color="default"
          variant="text"
          overrideLabel={intl.formatMessage(messages.bookMeetingButton)}
          isAdmin
        />
      </div>
      {customerupdates && customerupdates.MeetingResponse?.length > 0 && (
        <div className={classes.newlyAddedContainer}>
          <BookingsList
            variant={Variant.ADMIN_NEWLY_ADDED}
            bookingsList={customerupdates.MeetingResponse}
            reloadBookings={loadUpcomingBookings}
          />
        </div>
      )}
      <SearchBar
        minQueryLength={10}
        placeholder={intl.formatMessage(messages.searchbarPlaceholder)}
        onSubmit={(newQuery) => setQuery(newQuery)}
      />
      <BookingsList
        variant={Variant.ADMIN_UPCOMING_BOOKINGS}
        bookingsList={upcomingBookings?.data}
        reloadBookings={loadBookings}
      />
      <CustomPagination
        onChange={setPage}
        pagination={upcomingBookings?.pagination}
      />
    </>
  );
};

export default AdminBookings;
