import { createStyles, makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minHeight: "80vh",
      textAlign: "center",
      paddingTop: "20vh",
    },
    description: {
      maxWidth: "500px",
      textAlign: "center",
      marginRight: "auto",
      marginLeft: "auto",
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  })
);
