import { useEffect } from "react";
import { useHistory } from "react-router-dom";

function useIgnoreBackButton() {
  const history = useHistory();

  useEffect(() => {
    // Block all navigation attempts (including back navigation)
    // @ts-ignore
    const unblock = history.block((location, action) => {
      // If the action is POP (back button), prevent navigation
      if (action === "POP") {
        return false; // This blocks the back navigation
      }
      return true; // Allow other navigation actions
    });

    // Cleanup: Unblock when the component unmounts
    return () => {
      unblock();
    };
  }, [history]);

  return null;
}

export default useIgnoreBackButton;
