import React, { useEffect, useState } from "react";
import { Paper, Typography } from "@material-ui/core";
import { useIntl } from "react-intl";
import { StringParam, useQueryParam } from "use-query-params";
import { Redirect, useHistory } from "react-router-dom";
import { useSnackbar } from "notistack";
import { useStyles } from "./InviteVerify.style";
import { useCustomerId } from "../../Providers/CustomerProvider/CustomerProvider";
import { inviteService } from "../../Providers/ServiceProvider/ServiceProvider";
import VerificationCodeField from "../../Components/Fields/VerificationCodeField/VerificationCodeField";
import { RoutePath } from "../../Components/Routes/RoutePath";
import { messages } from "./InviteVerify.messages";
import VerifyPersonNumberDialog from "../../Components/Dialogs/VerifyPersonNumberDialog/VerifyPersonNumberDialog";
import { VerifyInviteCodeResponse } from "../../generated/invite-api";
import { Key, setSessionStorageItem } from "../../Utils/SessionStorage";

const InviteVerify = () => {
  const intl = useIntl();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [inviteId] = useQueryParam("id", StringParam);
  const [inviteStatus, setInviteStatus] = useState<number>();
  const customerId = useCustomerId();
  const classes = useStyles();
  const [
    verifyInviteCodeResponse,
    setVerifyInviteCodeResponse,
  ] = useState<VerifyInviteCodeResponse>();
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const generateCode = () => {
    if (customerId && inviteId !== null && inviteId) {
      inviteService(false)
        .sendInviteCode({ customerId, body: { inviteId } })
        .then(() => setInviteStatus(200))
        .catch((res) => setInviteStatus(res.status));
    }
  };

  const verifyCode = (code: string) => {
    if (customerId && inviteId !== null && inviteId) {
      inviteService(false)
        .verifyInviteCode({
          customerId,
          body: { inviteId, code },
        })
        .then(setVerifyInviteCodeResponse)
        .catch(() => {
          enqueueSnackbar(intl.formatMessage(messages.error), {
            variant: "error",
          });
        });
    }
  };

  useEffect(() => {
    generateCode();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (inviteStatus === 404) {
      history.replace(RoutePath.LOGIN);
    }
  }, [inviteStatus]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (verifyInviteCodeResponse?.token) {
      setSessionStorageItem(
        Key.JWT_TOKEN,
        verifyInviteCodeResponse.token
      ).then(() => setIsDialogOpen(true));
    }
  }, [verifyInviteCodeResponse?.token]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!inviteId) {
    return <Redirect to={RoutePath.LOGIN} />;
  }

  if (!inviteStatus) {
    return null;
  }

  return (
    <Paper className={classes.root}>
      <VerifyPersonNumberDialog
        open={verifyInviteCodeResponse !== undefined && isDialogOpen}
      />
      <Typography align="center" variant="h4" component="h1">
        {intl.formatMessage(messages.title)}
      </Typography>
      <Typography
        className={classes.description}
        align="center"
        variant="subtitle1"
        component="h6"
      >
        {intl.formatMessage(messages.description)}
      </Typography>
      <VerificationCodeField onSubmit={(code) => verifyCode(code)} />
    </Paper>
  );
};

export default InviteVerify;
