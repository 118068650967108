import React, { useState } from "react";
import { Button } from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import { useSnackbar } from "notistack";
import { useIntl } from "react-intl";
import { UserAuthorityType, UserRoleType } from "../../../generated/user-api";
import PermissionController from "../../PermissionController/PermissionController";
import AddOrCreateUserDialog from "../../Dialogs/AddOrCreateUserDilog/AddOrCreateUserDialog";
import { User } from "../../../Models/User";
import { Variant } from "../../Dialogs/AddOrCreateUserDilog/Components/SearchField/SearchField";
import FeatureController from "../../FeatureController/FeatureController";
import { CustomerFeatureType } from "../../../generated/customersettings-api";
import { messages } from "./InviteUserButton.messages";

interface Props {
  onCreated?: (user: User) => void;
  label: string;
  dialogTitle: string;
  userRole: UserRoleType;
  color?: "inherit" | "primary" | "secondary" | "default";
  className?: string;
  variant?: "text" | "outlined" | "contained";
}

const InviteUserButton = (props: Props) => {
  const {
    userRole,
    label,
    dialogTitle,
    onCreated,
    color,
    className,
    variant,
  } = props;
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const intl = useIntl();

  const getRequiredFeature = () => {
    switch (userRole) {
      case UserRoleType.External:
        return CustomerFeatureType.InviteExternal;
      case UserRoleType.Client:
        return CustomerFeatureType.InviteClient;
      case UserRoleType.Staff:
        return CustomerFeatureType.InviteStaff;
      default:
        return CustomerFeatureType.InviteStaff;
    }
  };

  const handleOnInvited = (user: User) => {
    enqueueSnackbar(intl.formatMessage(messages.successMessage), {
      variant: "success",
    });

    if (onCreated) {
      onCreated(user);
    }
  };

  return (
    <FeatureController requiredFeature={getRequiredFeature()}>
      <AddOrCreateUserDialog
        onCreated={handleOnInvited}
        isOpen={isDialogOpen}
        title={dialogTitle}
        userRole={userRole}
        variant={Variant.INVITE}
        sendNotification
        onClose={() => setIsDialogOpen(false)}
      />
      <PermissionController allowedRoles={[UserRoleType.Staff]}>
        <PermissionController
          overrideCurrentUserRole={userRole}
          requiredAuthoritiesStaff={[UserAuthorityType.CreateUserStaff]}
          requiredAuthoritiesClient={[UserAuthorityType.CreateUserClient]}
          requiredAuthoritiesExternal={[UserAuthorityType.CreateUserClient]}
        >
          <Button
            size="small"
            color={color || "default"}
            variant={variant || "text"}
            className={className}
            startIcon={<EmailIcon />}
            onClick={() => setIsDialogOpen(true)}
          >
            {label}
          </Button>
        </PermissionController>
      </PermissionController>
    </FeatureController>
  );
};

export default InviteUserButton;
